import React, { FC } from "react";
import { SEO } from "@wealthComponents/seo";
import {
  AuthPage,
  PromoTwo,
  PromoTwoBody,
} from "@ifgengineering/client-auth-components";

const authBgColor = "linear-gradient(180deg, #192a36, #000000)";

const NewAuth: FC = () => {
  const intendedVisitLink = localStorage.getItem("intended_visit_link");
  const authRedirect = intendedVisitLink || "/app/compare";
  return (
    <>
      <SEO title="Islamic Finance Guru" />
      <AuthPage
        Promo={() => <PromoTwo />}
        MobileFooter={() => <PromoTwoBody />}
        showHeader={true}
        showTrustBox={true}
        backgroundColor={authBgColor}
        authRedirect={authRedirect}
      />
    </>
  );
};

export default NewAuth;
